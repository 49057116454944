.targetedNavigation {
  top: 75px;
}
.targetedNavigation .levelOneItems li {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 2rem;
}
.targetedNavigation .levelOneItems li a {
  font-weight: 900;
  text-transform: uppercase;
}
.targetedNavigation .levelOneItems li .subNavDisplay li a {
  font-weight: 700;
  text-transform: none;
}
/* async:start */
li.newNavigationItem a.mainLink:before,
li.bakeOffContestItem a.mainLink:before {
  font-family: 'Agbalumo', sans-serif;
  color: #002e6d;
  text-transform: none;
  font-weight: normal;
}
/* async:end */
