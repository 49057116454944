/* async:start */
.footerSocialContainer {
  padding: 50px 20px 0;
}
.footerSocialContainer .footerSocial {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-direction: column;
      flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 25px;
  border-bottom: 2px solid #fff;
}
.footerSocialContainer .footerSocial .footerLogo {
  background: url(/Styles/Themes/PB/Images/img-logo.svg) no-repeat;
  background-size: cover;
  margin: 0 0 25px;
  display: block;
  width: 111px;
  height: 110px;
}
.footerSocialContainer .socialLinks .channelLinks {
  background-color: #002e6d;
}
.footerSocialContainer .socialLinks .channelLinks li a {
  background-color: #002e6d;
}
.footerSocialContainer .socialLinks .channelLinks li.iconFacebook a::before,
.footerSocialContainer .socialLinks .channelLinks li.iconPinterest a::before,
.footerSocialContainer .socialLinks .channelLinks li.iconTikTok a::before {
  color: #fff;
}
.footerSocialContainer .socialLinks .channelLinks li.iconInstagram a::before,
.footerSocialContainer .socialLinks .channelLinks li.iconYouTube a::before {
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink {
  font-weight: bold;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink:focus {
  outline-color: white;
}
.footerLinksContainer .footerLinks .copyright {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
/* async:end */
