@media screen and (min-width:1000px) {
/* async:start */
.footerSocialContainer .footerSocial {
  -ms-flex-direction: row;
      flex-direction: row;
}
.footerSocialContainer .footerSocial .footerLogo {
  margin: 0;
  width: 120px;
  height: 118px;
}
/* async:end */

}
