.channelLinks {
  margin: -30px -10px 0;
  white-space: nowrap;
  position: relative;
  border-bottom: 1px solid #f5f5f6;
}
.channelLinks:after {
  content: ' ';
  overflow: hidden;
  position: absolute;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  width: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}
.channelLinks .channelLinksInner {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.channelLinks .channelLinksInner::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.channelLinks h3 {
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-family: 'Poppins', sans-serif;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 15px;
}
.channelLinks ul {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}
.channelLinks li {
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-family: 'Poppins', sans-serif;
  color: #002e6d;
  padding: 15px 5px;
}
.channelLinks li:after {
  content: "|";
  padding: 0 8px;
}
.channelLinks li:last-child {
  padding-right: 30px;
}
.channelLinks li:last-child:after {
  content: "";
  padding: 0;
}
.channelLinks.hasImages {
  white-space: initial;
}
.channelLinks.hasImages:after {
  display: none;
}
.channelLinks.hasImages h3 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: #002e6d;
  scroll-snap-align: start;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  margin-bottom: 0;
  text-align: left;
  width: 20%;
}
.channelLinks.hasImages h3 a {
  display: inline-block;
}
.channelLinks.hasImages h3 a {
  color: #3273f8;
  text-decoration: underline;
}
.channelLinks.hasImages h3 a:hover,
.channelLinks.hasImages h3 a:focus {
  text-decoration: none;
}
.channelLinks.hasImages h3 a {
  color: #002e6d;
}
.channelLinks.hasImages li {
  scroll-snap-align: start;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  width: 30%;
  margin-right: 5px;
}
.channelLinks.hasImages li a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.channelLinks.hasImages li a .linkTitle {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  line-height: 1.25;
  padding-left: 10px;
  text-align: left;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
.channelLinks.hasImages li a img {
  -ms-flex: 0 0 90px;
      flex: 0 0 90px;
  max-width: 90px;
  -ms-flex-order: -1;
      order: -1;
}
.channelLinks.hasImages li:after {
  display: none;
}
/* async:start */
.socialLinks .channelLinks {
  margin: 0;
  border-bottom: none;
}
.socialLinks .channelLinks:after {
  display: none;
}
.socialLinks .channelLinks .channelLinksInner {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
}
.socialLinks .channelLinks h3 {
  color: inherit;
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: #002e6d;
  color: #fff;
  width: 100%;
}
.socialLinks .channelLinks h3 a {
  display: inline-block;
}
.socialLinks .channelLinks h3 a {
  color: #3273f8;
  text-decoration: underline;
}
.socialLinks .channelLinks h3 a:hover,
.socialLinks .channelLinks h3 a:focus {
  text-decoration: none;
}
.socialLinks .channelLinks h3 a {
  color: #002e6d;
}
.socialLinks .channelLinks ul {
  overflow-x: visible;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.socialLinks .channelLinks li {
  padding: 0 12px;
}
.socialLinks .channelLinks li:before {
  display: none;
}
.socialLinks .channelLinks li:last-child {
  padding-right: 12px;
}
.socialLinks .channelLinks li:after {
  content: "";
  padding: 0;
}
.socialLinks .channelLinks li a {
  display: inline-block;
  background: white;
}
.socialLinks .channelLinks li a:before {
  font-family: 'Icon Fonts';
  font-size: 1.75rem;
  line-height: 40px;
  color: #555;
  margin: auto;
}
.socialLinks .channelLinks li a:hover,
.socialLinks .channelLinks li a:focus {
  text-decoration: none;
}
.socialLinks .channelLinks li.iconFacebook a:before {
  content: "\e92d";
  color: #3b5a99;
}
.socialLinks .channelLinks li.iconPinterest a:before {
  content: "\e90c";
  color: #bb171f;
}
.socialLinks .channelLinks li.iconInstagram a:before {
  content: "\e912";
  color: #dc2743;
  background-image: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.socialLinks .channelLinks li.iconTwitter a:before {
  content: "\e902";
  color: #00acec;
}
.socialLinks .channelLinks li.iconYouTube a:before {
  content: "\e900";
  color: #e32d28;
  background: -webkit-linear-gradient(#e32d28, #c0191f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.socialLinks .channelLinks li.iconTikTok a:before {
  content: "\e936";
  color: #000000;
}
/* async:end */
