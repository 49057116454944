.channelLinks {
  background-color: #3273f8;
  margin-bottom: 20px;
  border-bottom: 0;
}
.channelLinks:after {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #3273f8);
}
.channelLinks h3 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: #002e6d;
  color: #fff;
  margin-bottom: 0;
}
.channelLinks h3 a {
  display: inline-block;
}
.channelLinks h3 a {
  color: #3273f8;
  text-decoration: underline;
}
.channelLinks h3 a:hover,
.channelLinks h3 a:focus {
  text-decoration: none;
}
.channelLinks h3 a {
  color: #002e6d;
}
.channelLinks a {
  color: #fff;
}
.channelLinks.hasImages h3 {
  color: #fff;
}
/* async:start */
.socialLinks .channelLinks {
  background-color: #fff;
}
.socialLinks .channelLinks h3 {
  color: #3273f8;
}
.socialLinks .channelLinks li a:before {
  font-size: 2rem;
}
/* async:end */
