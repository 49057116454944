@media screen and (min-width:1000px) {
.pageContainer > .channelLinks {
  margin-top: -30px;
  margin-left: -100%;
  margin-right: -100%;
}
.pageContainer > .channelLinks .channelLinksInner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
/* async:start */
.socialLinks .channelLinks li a {
  transition: all 200ms ease;
}
.socialLinks .channelLinks li a:hover,
.socialLinks .channelLinks li a:focus {
  -webkit-transform: scale(1.075);
          transform: scale(1.075);
}
.socialLinks .channelLinks li a:before {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 2.5rem;
  line-height: 2.5rem;
}
/* async:end */

}
