@media screen and (min-width:1000px) {
.simpleNavToggle {
  display: none;
}
.targetedNavigation {
  display: block;
  background: #002557;
  overflow: visible;
  position: relative;
  top: 0;
  -webkit-transform: none;
          transform: none;
  box-shadow: none;
  padding: 10px 20px;
  width: calc(100% - 40px);
}
.targetedNavigation .levelOneItems {
  position: static;
  background: inherit;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  gap: 20px;
}
.targetedNavigation .levelOneItems li a:focus {
  outline-offset: 3px;
}
.targetedNavigation .levelOneItems > li,
.targetedNavigation .levelOneItems > li.withChildren {
  position: static;
  -ms-flex-item-align: center;
      align-self: center;
  padding: 0;
  background: #002557;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  overflow: visible;
  border-radius: 0;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.targetedNavigation .levelOneItems > li:before,
.targetedNavigation .levelOneItems > li.withChildren:before,
.targetedNavigation .levelOneItems > li:after,
.targetedNavigation .levelOneItems > li.withChildren:after {
  width: 0;
  height: 0;
  background: none;
}
.targetedNavigation .levelOneItems > li > a,
.targetedNavigation .levelOneItems > li.withChildren > a,
.targetedNavigation .levelOneItems > li > span,
.targetedNavigation .levelOneItems > li.withChildren > span {
  color: #fff;
  display: inline-block;
  margin: 0;
  text-align: center;
  height: 100%;
  padding: 0px;
  line-height: 30px;
  text-decoration: none;
  white-space: nowrap;
}
.targetedNavigation .levelOneItems > li:hover,
.targetedNavigation .levelOneItems > li.withChildren:hover {
  border-bottom: 2px solid #fff;
}
.targetedNavigation .levelOneItems > li:hover > a,
.targetedNavigation .levelOneItems > li.withChildren:hover > a {
  color: #fff;
}
.targetedNavigation .levelOneItems li.withChildren a {
  overflow: visible;
  white-space: normal;
}
.targetedNavigation .levelOneItems li.withChildren a:after {
  border: none;
}
.targetedNavigation .levelOneItems li.withChildren > a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.targetedNavigation .levelOneItems li.withChildren > a:after {
  position: static;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.5rem;
  line-height: 0.5rem;
  font-family: 'Icon Fonts';
  content: "\e92b";
  padding: 0 3px;
  transition-duration: 0.2s;
}
.targetedNavigation .levelOneItems li.withChildren.expanded {
  padding-bottom: 0;
}
.targetedNavigation .levelOneItems li.withChildren.expanded > a {
  color: #fff;
}
.targetedNavigation .levelOneItems li.withChildren.expanded > a:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition-duration: 0.2s;
}
.levelOneItems li.withChildren .subNavDisplay {
  top: 54px;
  max-height: none;
  padding: 16px 10%;
  background: #fff;
  box-sizing: border-box;
  color: #3273f8;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  left: 0;
  right: 0;
  position: absolute;
  box-shadow: 0 4px 4px -3px rgba(0, 0, 0, 0.1);
  display: none;
}
.levelOneItems li.withChildren .subNavDisplay .listSection {
  display: block;
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
}
.levelOneItems li.withChildren .subNavDisplay .subNavSection {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  padding-top: 0;
}
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
}
.levelOneItems li.withChildren .subNavDisplay .listSection ~ .subNavSection {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
}
.levelOneItems li.withChildren .subNavDisplay .listSection ~ .subNavSection .levelTwoItems > li {
  -ms-flex: 0 0 33%;
      flex: 0 0 33%;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems {
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 0;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li {
  position: relative;
  padding: 20px;
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #fff;
  white-space: normal;
  box-sizing: border-box;
  overflow: hidden;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li > a,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li > a,
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li > span,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li > span {
  display: block;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li > a:after,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li > a:after {
  content: "\2192";
  position: static;
  display: inline;
  margin-left: 5px;
  vertical-align: baseline;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li ul.levelThreeItems,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li ul.levelThreeItems {
  margin-top: 5px;
  display: block;
  max-height: none;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li ul.levelThreeItems li,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li ul.levelThreeItems li {
  background: #fff;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li ul.levelThreeItems li a,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li ul.levelThreeItems li a {
  font-weight: normal;
  margin: 5px 0;
  color: #002e6d;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li ul.levelThreeItems li a:focus,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li ul.levelThreeItems li a:focus {
  outline-offset: 0;
}
.levelOneItems li.withChildren.expanded .subNavDisplay {
  display: -ms-flexbox;
  display: flex;
}
/* async:start */
li.newNavigationItem a.mainLink:before,
li.bakeOffContestItem a.mainLink:before {
  top: -10px;
}
/* async:end */

}
