/* async:start */
.iconYouTube:before {
  font-family: 'Icon Fonts';
  content: "\e900";
}
.iconWhatsApp:before {
  font-family: 'Icon Fonts';
  content: "\e901";
}
.iconTwitter:before {
  font-family: 'Icon Fonts';
  content: "\e902";
}
.iconThumbsUp:before {
  font-family: 'Icon Fonts';
  content: "\e903";
}
.iconTBSP_Print:before {
  font-family: 'Icon Fonts';
  content: "\e904";
}
.iconTBSP_Email:before {
  font-family: 'Icon Fonts';
  content: "\e905";
}
.iconTBSP_Bubble:before {
  font-family: 'Icon Fonts';
  content: "\e906";
}
.iconSearch:before {
  font-family: 'Icon Fonts';
  content: "\e92c";
}
.iconQRV_Divider:before {
  font-family: 'Icon Fonts';
  content: "\e90a";
}
.iconQRV_Bubble:before {
  font-family: 'Icon Fonts';
  content: "\e90b";
}
.iconPinterest:before {
  font-family: 'Icon Fonts';
  content: "\e90c";
}
.iconPB_Print:before {
  font-family: 'Icon Fonts';
  content: "\e90d";
}
.iconPB_Email:before {
  font-family: 'Icon Fonts';
  content: "\e90e";
}
.iconPB_Divider:before {
  font-family: 'Icon Fonts';
  content: "\e90f";
}
.iconPB_Bubble:before {
  font-family: 'Icon Fonts';
  content: "\e910";
}
.iconMobileDevice:before {
  font-family: 'Icon Fonts';
  content: "\e911";
}
.iconInstagram:before {
  font-family: 'Icon Fonts';
  content: "\e912";
}
.iconHeartCheck:before {
  font-family: 'Icon Fonts';
  content: "\e913";
}
.iconHeart:before {
  font-family: 'Icon Fonts';
  content: "\e914";
}
.iconHeartFilled:before {
  font-family: 'Icon Fonts';
  content: "\e928";
}
.iconHeartEmpty:before {
  font-family: 'Icon Fonts';
  content: "\e929";
}
.iconChevronDown:before {
  font-family: 'Icon Fonts';
  content: "\e92b";
}
.iconHalfStar:before {
  font-family: 'Icon Fonts';
  content: "\e930";
}
.iconFullStar:before {
  font-family: 'Icon Fonts';
  content: "\e92f";
}
.iconEmptyStar:before {
  font-family: 'Icon Fonts';
  content: "\e931";
}
.iconFacebook:before {
  font-family: 'Icon Fonts';
  content: "\e92d";
}
.iconCookbook:before {
  font-family: 'Icon Fonts';
  content: "\e918";
}
.iconBC_Print:before {
  font-family: 'Icon Fonts';
  content: "\e91a";
}
.iconBC_Email:before {
  font-family: 'Icon Fonts';
  content: "\e919";
}
.iconBC_Divider:before {
  font-family: 'Icon Fonts';
  content: "\e91b";
}
.iconBC_Bubble:before {
  font-family: 'Icon Fonts';
  content: "\e91c";
}
.iconAskBetty:before {
  font-family: 'Icon Fonts';
  content: "\e91d";
}
.iconLMD_Print:before {
  font-family: 'Icon Fonts';
  content: "\e91a";
}
.iconArrowBackward {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.iconArrowForward {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.iconMenuArrow:before,
.iconArrowBackward:before,
.iconArrowForward:before {
  font-family: 'Icon Fonts';
  content: "\e91e";
}
.iconUncheckedCircle:before {
  font-family: 'Icon Fonts';
  content: "\e91f";
}
.iconCheckedCircle:before {
  font-family: 'Icon Fonts';
  content: "\e920";
}
.iconTrashCan:before {
  font-family: 'Icon Fonts';
  content: "\e922";
}
.iconCloseX:before {
  font-family: 'Icon Fonts';
  content: "\e923";
}
.iconHelpToolTip:before {
  font-family: 'Icon Fonts';
  content: "\e924";
}
.iconCheckMark:before {
  font-family: 'Icon Fonts';
  content: "\e925";
}
.iconGooglePlus:before {
  font-family: 'Icon Fonts';
  content: "\e921";
}
.iconAsk:before {
  font-family: 'Icon Fonts';
  content: "\e926";
}
.iconKitchenTested {
  content: "\e927";
}
.iconKitchenTested:before {
  font-family: 'Icon Fonts';
  content: "\e927";
}
.iconArrowRight:before {
  font-family: 'Icon Fonts';
  content: "\e92a";
}
.iconQuestionAnswer:before {
  font-family: 'Icon Fonts';
  content: "\e92e";
}
.iconShop:before {
  font-family: 'Icon Fonts';
  content: "\e95e";
}
.iconCart:before {
  font-family: 'Icon Fonts';
  content: "\e93f";
}
.iconShare:before {
  font-family: 'Icon Fonts';
  content: "\e95f";
}
.iconShareAndroid:before {
  font-family: 'Icon Fonts';
  content: "\e940";
}
.iconPlayRound:before {
  font-family: 'Icon Fonts';
  content: "\e960";
}
.iconCopyLink:before {
  font-family: 'Icon Fonts';
  content: "\e961";
}
.icon-check:before {
  font-family: 'Icon Fonts';
  content: "\e932";
}
.icon-circleCheck:before {
  font-family: 'Icon Fonts';
  content: "\e933";
}
.icon-circleError:before {
  font-family: 'Icon Fonts';
  content: "\e934";
}
.icon-x:before {
  font-family: 'Icon Fonts';
  content: "\e935";
}
.iconTikTok:before {
  font-family: 'Icon Fonts';
  content: "\e936";
}
.iconRefresh:before {
  font-family: 'Icon Fonts';
  content: "\e937";
}
.iconSparkle:before {
  font-family: 'Icon Fonts';
  content: "\e939";
}
.iconSparkleDuo:before {
  font-family: 'Icon Fonts';
  content: "\e938";
}
.iconGift:before {
  font-family: 'Icon Fonts';
  content: "\e93a";
}
.iconHollowEmail:before {
  font-family: 'Icon Fonts';
  content: "\e93b";
}
.iconHollowClose:before {
  font-family: 'Icon Fonts';
  content: "\e93c";
}
.iconPlay:before {
  font-family: 'Icon Fonts';
  content: "\e93d";
}
.iconPause:before {
  font-family: 'Icon Fonts';
  content: "\e93e";
}
.iconPlayTriangle:before {
  font-family: 'Icon Fonts';
  content: "\e941";
}
.iconQRVHeartFilled:before {
  font-family: 'Icon Fonts';
  content: "\e942";
}
.iconQRVHeart:before {
  font-family: 'Icon Fonts';
  content: "\e943";
}
.iconQRVEmail:before {
  font-family: 'Icon Fonts';
  content: "\e944";
}
.iconQRVPrint:before {
  font-family: 'Icon Fonts';
  content: "\e945";
}
.iconQRVSearch:before {
  font-family: 'Icon Fonts';
  content: "\e946";
}
.iconQRVShare:before {
  font-family: 'Icon Fonts';
  content: "\e947";
}
.iconQRVShop:before {
  font-family: 'Icon Fonts';
  content: "\e948";
}
.iconQRVDoodleLeft:before {
  font-family: 'Icon Fonts';
  content: "\e949";
}
.iconQRVDoodleRight:before {
  font-family: 'Icon Fonts';
  content: "\e94a";
}
.iconDownload:before {
  font-family: 'Icon Fonts';
  content: "\e94b";
}
.iconJumpTo:before {
  font-family: 'Icon Fonts';
  content: "\e94c";
}
.iconPrint:before {
  font-family: 'Icon Fonts';
  content: "\e94d";
}
.iconAiSummary:before {
  font-family: 'Icon Fonts';
  content: "\e94e";
}
/* async:end */
.stars {
  position: relative;
  text-align: left !important;
  display: inline-block;
  line-height: 15px;
}
.stars:before,
.stars:after {
  font-family: 'Icon Fonts';
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  line-height: 20px;
  color: #002e6d;
  content: "\e931\e931\e931\e931\e931";
  letter-spacing: 1px;
}
.stars:after {
  content: "";
  position: absolute;
  left: 0;
  color: #002e6d;
}
.stars.star-10:after {
  content: "\e930";
}
.stars.star-20:after {
  content: "\e92f";
}
.stars.star-30:after {
  content: "\e92f\e930";
}
.stars.star-40:after {
  content: "\e92f\e92f";
}
.stars.star-50:after {
  content: "\e92f\e92f\e930";
}
.stars.star-60:after {
  content: "\e92f\e92f\e92f";
}
.stars.star-70:after {
  content: "\e92f\e92f\e92f\e930";
}
.stars.star-80:after {
  content: "\e92f\e92f\e92f\e92f";
}
.stars.star-90:after {
  content: "\e92f\e92f\e92f\e92f\e930";
}
.stars.star-100:after {
  content: "\e92f\e92f\e92f\e92f\e92f";
}
