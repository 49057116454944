@media screen and (min-width:1000px) {
.targetedNavigation .levelOneItems > li,
.targetedNavigation .levelOneItems > li.withChildren {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.875rem;
}
.targetedNavigation .levelOneItems > li a,
.targetedNavigation .levelOneItems > li.withChildren a {
  text-transform: unset;
}
.targetedNavigation .levelOneItems > li a:focus,
.targetedNavigation .levelOneItems > li.withChildren a:focus {
  outline-color: white;
}
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li > a,
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li > span {
  font-weight: 900;
  text-transform: uppercase;
}
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li > a:focus,
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li > span:focus {
  outline-color: #002e6d;
}
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li ul.levelThreeItems li a {
  font-weight: 700;
}
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li ul.levelThreeItems li a:focus {
  outline-color: #002e6d;
}
/* async:start */
li.newNavigationItem a.mainLink:before,
li.bakeOffContestItem a.mainLink:before {
  color: #ff9404;
}
/* async:end */

}
