.userLinks .headerAnonymous .headerLogin li a {
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 100px;
  font-weight: 900;
}
.userLinks .headerAnonymous .headerLogin li a:focus {
  outline-color: white;
}
.userLinks .headerAuthenticated {
  font-weight: 700;
}
.userLinks .headerAuthenticated .headerMyAccount button.myAccount:focus {
  outline-color: white;
}
.userLinks .headerAuthenticated .favoritesButton:focus {
  outline-color: white;
}
/* async:start */
.userLinks .headerAuthenticated .headerMyAccount ul.drop li a,
.userLinks .headerAuthenticated .headerMyAccount ul.drop li button {
  font-weight: 700;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li a:focus,
.userLinks .headerAuthenticated .headerMyAccount ul.drop li button:focus {
  outline-color: #002e6d;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li #logout {
  text-transform: none;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li #logout:focus {
  outline-color: #002e6d;
}
.userLinks .headerAuthenticated .headerMyAccount.expanded button.myAccount:focus {
  outline-color: #002e6d;
}
/* async:end */
